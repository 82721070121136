import React, { useState, useEffect } from 'react';
import gAuth from '../../../assets/images/gAuth.png';
import aAuth from '../../../assets/images/apple.png';
import { Button} from 'reactstrap';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { COGNTIO_URL } from '../../../config/app';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { registerUser, cognitoRegister } from '../../../actions/auth';
import moment from 'moment-timezone';
import { getAge } from '../../../helpers/common-methods';
import './SignUpCompV2.css';
import FloatingInput from '../../FloatingInput/FloatingInput';
import EmailVerification from '../../../modals/EmailVerification';
import { useColors } from '../../../helpers/CustomHooks/useColors';

const SignUpCompV2 = (props) => {
    const history = useHistory();
    const [localError, setLocalError] = useState();
    const [visible, setVisible] = useState(false);
    const [showVerification, setShowVerification] = useState(false);
    const [loading, setLoading] = useState(false);
    const [uuid, setUuid] = useState(null);
    const [formData, setFormData] = useState({ password: '', email: '' });
    const { control, register, handleSubmit, formState: { errors }, setError } = useForm()
    const location = useLocation()
    const parsed = queryString.parse(location.search)
    const {switchScreen, internal} = props
    const color = useColors()

    const onSubmit = (data) => {
        var dateString = moment(data.date).format("MM/DD/YYYY")
        if(parseInt(getAge(dateString)) < 19){
            setError("date", { type: 'custom', message: 'You must be at least 19 years old'})
        }else{
            setLoading(true)
            setFormData({ email: data.email, password: data.password })
            var params = {
                first_name: data.firstName,
                last_name: data.lastName,
                username: data.username,
                email: data.email,
                password: data.password,
                dob: moment(data.date).format("MM/DD/YYYY"),
            }
            if(parsed.viaPromoLink){
                params["viaPromoLink"] = 1
            } 
            if(parsed.ref) {
                params["ref"] = parsed.ref
                params["refType"] = parsed.refType
            }

            if(parsed?.pid){
                params["sk_id"] = parsed.pid
            }

            props.cognitoRegister(params).then((resp) => {
                setShowVerification(true);
                setUuid(resp.message);
                setLoading(false);
            }).catch((err) => {
                setLocalError(err.message);
                setLoading(false);
                setVisible(true);
            })            
        }
    }

    const getCogntioUrl = () => {

        let url = COGNTIO_URL + "&state=c=1"

        if(parsed?.ref){
            url += ",ref="+parsed.ref
        }

        if(parsed?.refType){
            url += ",ref_type="+parsed.refType
        }

        if(parsed?.pid){
            url += ",pid="+parsed.pid
        }
        
        return url
    }

    const getDefaultDate = () => {
        let someDate = new Date();
        someDate.setDate(someDate.getDate());
        let date = someDate.toISOString().substr(0, 10);
        return date;
    }

    const handleLogin = () => {
        if(internal){
            switchScreen("signin")
        }else{
            history.push('/signin')
        }
    }

    return (
        <div className='login-main-section d-flex flex-column justify-content-center flex1'>
            <div className='d-flex justify-content-center'>
                {showVerification ? (
                    <div className='flex-grow-1 my-3'>
                        <EmailVerification
                            password={formData.password}
                            email={formData.email}
                        />
                    </div>
                ) : (
                    <div className='flex-grow-1 my-3'>
                    {/* <div className='login'>
                        <h2>Sign Up</h2>
                        <p className='m-0'>Let's create your Bettle account!</p>
                        <p className='m-0' style={{color: Colors.colorSecondary}}>Already have an Account? <span className='mt-4' onClick={() => history.push('/signin')} style={{ color: '#73b8f0', cursor: 'pointer' }}>Log in</span></p>
                    </div> */}

                    <div className="login-head-container mx-md-5 mx-2">
                        <h1 style={{fontSize: "16px", fontWeight: "500"}} >Sign Up</h1>
                    </div>

                    <div className='my-4 px-md-5 px-2' >
                        <p className='welcome-heading' >Let’s create your Bettle Account!</p>
                        <div className='mb-2'>
                            <span style={{ color: color.colorDanger }}>{localError}</span>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {/* Field Group */}
                            <div className='d-md-flex d-sm-block'>
                                <div className='flex1 mr-md-1'>
                                    <Controller
                                        control={control}
                                        render={({ field: { onChange, onBlur, value } }) =>
                                        {
                                            return <FloatingInput
                                                error={errors?.firstName?.message}
                                                type="text"
                                                label="First Name"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                            />
                                        }}
                                        name="firstName"
                                        rules={
                                            {required:"First Name is required"}
                                        }
                                        defaultValue={''}
                                    />
                                    {/* <input {...register("firstName", { required: true })} className='login-inputs form-control border-0' type='text' style={{ backgroundColor: Colors.inputBackground, color: Colors.inputText}} placeholder='First Name' />
                                    {errors.firstName && <ErrorText msg='First Name field is required' />} */}
                                </div>
                                <div className='flex1 ml-md-1'>
                                    <Controller
                                        control={control}
                                        render={({ field: { onChange, onBlur, value } }) =>
                                        {
                                            return <FloatingInput
                                                error={errors?.lastName?.message}
                                                type="text"
                                                label="Last Name"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                            />
                                        }}
                                        name="lastName"
                                        rules={
                                            {required:"Last Name is required"}
                                        }
                                        defaultValue={''}
                                    />
                                    {/* <input {...register("lastName", { required: true })} className='login-inputs form-control border-0' type='text' style={{ backgroundColor: Colors.inputBackground, color: Colors.inputText }} placeholder='Last Name' />
                                    {errors.lastName && <ErrorText msg='Last Name field is required' />} */}
                                </div>
                            </div>
                            {/* !Field Group */}

                            {/* Field Group */}
                            <div className='d-md-flex d-sm-block'>
                                {/* <div className='flex1 mr-md-1'>
                                    <input {...register("username", { required: true })} className='login-inputs form-control border-0' type='text' style={{ backgroundColor: Colors.inputBackground, color: Colors.inputText }} placeholder='Username' />
                                    {errors.username && <ErrorText msg='Username field is required' />}
                                </div> */}
                                <div className='flex1 ml-md-1'>
                                    <Controller
                                        control={control}
                                        render={({ field: { onChange, onBlur, value } }) =>
                                        {
                                            return <FloatingInput
                                                error={errors?.date?.message}
                                                type="date"
                                                label="Date of birth"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                            />
                                        }}
                                        name="date"
                                        rules={
                                            {required:"Date of birth is required"}
                                        }
                                        defaultValue={""}
                                    />
                                    {/* <input {...register("date", { required: "Dob is required" })} type="date" defaultValue={getDefaultDate()} className='login-inputs form-control border-0' style={{ backgroundColor: Colors.inputBackground, color: Colors.inputText}}  />
                                    {errors.date && <ErrorText msg={errors.date.message} />} */}
                                </div>
                            </div>
                            {/* !Field Group */}

                            {/* Field Group */}
                            <div className='d-md-flex d-sm-block'>
                                <div className='flex1 mr-md-1'>

                                    <Controller
                                        control={control}
                                        render={({ field: { onChange, onBlur, value } }) =>
                                        {
                                            return <FloatingInput
                                                error={errors?.email?.message}
                                                type="email"
                                                label="Email"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                            />
                                        }}
                                        name="email"
                                        rules={
                                            {required:"Email is required"}
                                        }
                                        defaultValue={""}
                                    />
                                    {/* <input {...register("email", { required: true })} className='login-inputs form-control border-0' type='email' style={{ backgroundColor: Colors.inputBackground, color: Colors.inputText }} placeholder='Email' />
                                    {errors.email && <ErrorText msg='Email field is required' />} */}
                                </div>
                                <div className='flex1 ml-md-1'>
                                    <Controller
                                        control={control}
                                        render={({ field: { onChange, onBlur, value } }) =>
                                        {
                                            return <FloatingInput
                                                error={errors?.password?.message}
                                                type="password"
                                                label="Password"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                            />
                                        }}
                                        name="password"
                                        rules={
                                            {required:"Password is required"}
                                        }
                                        defaultValue={""}
                                    />
                                    {/* <input {...register("password", { required: true })} className='login-inputs form-control border-0' type='password' style={{ backgroundColor: Colors.inputBackground, color: Colors.inputText }} placeholder='Password' />
                                    {errors.password && <ErrorText msg='Password field is required' />} */}
                                </div>
                            </div>
                            <div className='mt-2 text-center' style={{ fontStyle: 'normal', fontWeight: 400, fontSize: '12px', lineHeight: '16px' }}>
                                Clicking “Create Account” confirms you agree to our <span> <a href="/terms-and-conditions" className='green-font'>Terms</a> and <a href="/privacy" className='green-font'>Privacy Policy</a></span> that you are 18+ (21+ in MA).
                                <br/>You will also occasionally receive offers and messages from us.
                            </div>
                            <Button id="createAccountButtonSignUpPage" disabled={loading} style={{ width: "100%", height: "48px", fontSize: "16px" }} className="green-btn btn-pill mb-2 mt-3" type="submit">
                                {
                                    loading ? 'Creating...' :
                                        'SIGN UP'
                                }
                            </Button>

                            <Button id="googleSignUpPage" onClick={() => window.location.href = getCogntioUrl()} type="button" className="btn-pill loginBtn mb-2" >
                                <div className="d-flex align-items-center" >
                                    <div><img height="40" src={gAuth} /></div>
                                    <span style={{ margin: "auto", fontSize: "16px" }} >Sign up with Google</span>
                                </div>
                            </Button>
                            <br />
                            <Button id="appleSignUpPage" onClick={() => window.location.href = getCogntioUrl()} type="button" className="btn-pill loginBtn mb-2" >
                                <div className="d-flex align-items-center" >
                                    <div><img height="40" src={aAuth} /></div>
                                    <span style={{ margin: "auto", fontSize: "16px" }} >Sign up with Apple</span>
                                </div>
                            </Button>

                        </form>
                        <div className='d-flex justify-content-center mt-4' >
                            <p className='m-0'>Already have an Account? <span className='green-font' onClick={handleLogin} style={{ cursor: 'pointer' }}>Log in</span></p>
                        </div>
                    </div>
                </div>
                )}
            </div>
        </div>
    )
};


const mapStateToProps = (state) => {
    return {
        step: state.leagueJoin.step,
        leagueId: state.leagueJoin.leagueId
    };
};

const mapDispatchToProps = { registerUser, cognitoRegister };

// export default SignUpCompV2;
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignUpCompV2))