/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import SignInModal from '../../modals/Auth/SignInModal'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { set } from 'lodash'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=64b5f9951ca15b16e5bbf720").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class IndexView extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            signInModal: false,
            authScreen: '',
            loading: true,
        }
    }
        
    static get Controller() {
        if (Controller) return Controller

        try {
        Controller = require('../controllers/IndexController')
        Controller = Controller.default || Controller

        return Controller
        }
        catch (e) {
        if (e.code == 'MODULE_NOT_FOUND') {
            Controller = IndexView

            return Controller
        }

        throw e
        }
    }

    componentDidMount() {

        this.loadStyles().then(() => {
            // Once styles are loaded, set stylesLoaded to true
            this.setState({ loading: false });
        });


        const htmlEl = document.querySelector('html')
        htmlEl.dataset['wfPage'] = '64b5f9951ca15b16e5bbf72e'
        htmlEl.dataset['wfSite'] = '64b5f9951ca15b16e5bbf720'

        scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
            new Function(`
            with (this) {
                eval(arguments[0])
            }
            `).call(window, script)

            return next
        })

        return active.isAsync ? next : loading
        }))
    }

    navigateToSignIn() {
        this.props.history.push('/signin')
    }

    handleRegister() {
        if(this.props?.authenticated){
            this.props.history.push("/expCheck")
        }{
            // setAuthScreen("signup")
            // setSignInModal(true)

            this.setState({
                authScreen: 'signup',
                signInModal: true,
            })
        }
    }

    handleLogin() {
        if(this.props?.authenticated){
            this.props.history.push("/expCheck")
        }{
            // setAuthScreen("signin")
            // setSignInModal(true)

            this.setState({
                authScreen: 'signin',
                signInModal: true,
            })
        }
    }

    loadStyles() {
        return new Promise((resolve, reject) => {
            const stylesToLoad = [
                '/css/normalize.css',
                '/css/webflow.css',
                '/css/bettle-2-0.webflow.css',
                // Add more style URLs as needed
            ];
    
            const stylesLoaded = [];
    
            const loadStyle = (styleUrl) => {
                return new Promise((styleResolve, styleReject) => {
                    const linkElement = document.createElement('link');
                    linkElement.rel = 'stylesheet';
                    linkElement.href = styleUrl;
    
                    linkElement.onload = () => {
                        stylesLoaded.push(styleUrl);
                        styleResolve();
                    };
    
                    linkElement.onerror = (error) => {
                        styleReject(error);
                    };
    
                    document.head.appendChild(linkElement);
                });
            };
    
            const stylePromises = stylesToLoad.map((styleUrl) => loadStyle(styleUrl));
    
            Promise.all(stylePromises)
                .then(() => {
                    // All styles have been successfully loaded
                    resolve();
                })
                .catch((error) => {
                    // Handle style loading error here
                    reject(error);
                });
        });
    }
    

    render() {
        const proxies = IndexView.Controller !== IndexView ? transformProxies(this.props.children) : {
            
        }

    return (
        <>
        <span style={{ overflow: "hidden" }}>
            <span className={`af-view ${this.state.loading ? 'd-none' : ''}`}>
            <div className="af-class-body landingBody">
                <div data-w-id="a82f3be0-f9d2-2077-8207-5551c6c0f8ad" className="af-class-promo">
                <div className="af-class-promo_container">
                    <div className="af-class-promo_version af-class-long">
                    <div className="af-class-div-block-25">
                        <div className="af-class-promocontent af-class-words">Get a deposit match up to $100 on your first deposit!</div>
                        <div className="af-class-promocontent af-class-dollar">Already have an account? Refer a friend for a $10 bonus when they claim their team.</div>
                    </div>
                    </div>
                    <div className="af-class-promo_version af-class-short">
                    <div className="af-class-promocontent af-class-words">$100 Deposit Match </div>
                    <div className="af-class-promocontent af-class-dollar">Refer a friend get $10</div>
                    </div>
                </div>
                </div>
                <div className="af-class-nav">
                <div className="af-class-div-block-6"><img src="images/Bettle-Logo.svg" loading="lazy" width={187} alt className="af-class-logo af-class-main" /><img src="images/Bettle-Icon.svg" loading="lazy" width={68} alt className="af-class-logo af-class-icon" />
                    <div className="af-class-div-block-2">
                    <div className="af-class-button af-class-alt">
                        <div className="af-class-text-block pointer" onClick={() => this.handleRegister()}>Import Team</div>
                    </div>
                    <div className="af-class-button af-class-main pointer" onClick={() => this.handleLogin()}>
                        <div className="af-class-text-block pointer">Log In</div>
                    </div>
                    </div>
                </div>
                </div>
                <section className="af-class-section af-class-main"><img src="images/Bettle-Icon.svg" loading="lazy" width={287} data-w-id="09f1325c-8687-8117-79e4-d9e09ae9af80" alt className="af-class-image-2" />
                <div className="af-class-div-block-3">
                    <div className="af-class-div-block-24">
                    <div className="af-class-headline af-class-_1">
                        <div className="af-class-h1">Take your fantasy team to the next level</div>
                        <div className="af-class-subhead">Sync your season long team to compete and connect with the Bettle community</div>
                    </div>
                    <div className="af-class-headline af-class-_2">
                        <div className="af-class-h1">You've built the best team. <br />Now prove it.</div>
                        <div className="af-class-subhead">Import your squad into Bettle and win pots on a weekly basis</div>
                    </div>
                    </div>
                    <div className="af-class-div-block-4">
                        <img src="images/Logo.png" loading="lazy" alt onClick={() => window.open('https://play.google.com/store/apps/details?id=com.bettle.bettlelive&hl=en_US&gl=US')}/>
                        <img src="images/Logo-1.png" loading="lazy" alt onClick={() => window.open('https://apps.apple.com/us/app/bettle-fantasy-sports/id1591274931')} />
                        </div>
                </div>
                <div className="af-class-colorblock" />
                <div data-poster-url="images/hero-poster.jpg" data-video-urls="videos/bettle-bg-video.mp4" data-autoplay="true" data-loop="true" data-wf-ignore="true" className="af-class-background-video w-background-video w-background-video-atom">
                    <video id="3be5bddd-820f-a857-8bc5-ab17583e8a93-video" autoPlay loop style={{backgroundImage: 'images/hero-poster.jpg'}} muted playsInline data-wf-ignore="true" data-object-fit="cover">
                    <source src="videos/bettle-bg-video.mp4" data-wf-ignore="true" />
                    {/* <source src="https://uploads-ssl.webflow.com/64b5f9951ca15b16e5bbf720/64b6009384d27e4bfd012c0f_pexels-diva-plavalaguna-6194810 (2160p)-transcode.webm" data-wf-ignore="true" /> */}
                    </video>
                </div>
                </section>
                <section className="af-class-section-2">
                <div className="af-class-sectioncontainer">
                    <div className="af-class-cardcontainer">
                    <div data-w-id="4ee86301-5125-8f1d-eb0a-515b441decb1" className="af-class-card af-class-left">
                        <div className="af-class-image af-class-_1" />
                        <div className="af-class-textcontainer">
                        <div className="af-class-header">Compete</div>
                        <div className="af-class-bodycopy">Use your favorite season-long teams to challenge anyone in the Bettle universe to unlimited, weekly matches for cash.</div>
                        </div>
                    </div>
                    <div data-w-id="9ebd6672-b8cc-68d4-8628-4af785afe20c" className="af-class-card af-class-middle">
                        <div className="af-class-image af-class-_2" />
                        <div className="af-class-textcontainer">
                        <div className="af-class-header">Connect</div>
                        <div className="af-class-bodycopy">The Clubhouse connects you with other like-minded enthusiasts that treat fantasy as a way of life. Get some trade advice, find that next sleeper, or just talk about the latest news with some fellow die hards.</div>
                        </div>
                    </div>
                    <div className="af-class-card af-class-right">
                        <div className="af-class-image af-class-_3" />
                        <div className="af-class-textcontainer">
                        <div className="af-class-header">Built-in Research</div>
                        <div className="af-class-bodycopy">Bettle’s in-app analytics and &nbsp;partnerships with industry experts helps you maintain your competitive edge.</div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>
                <section className="af-class-banner">
                <div className="af-class-div-block-7">
                    <div className="af-class-div-block-9 af-class-div-block-10 af-class-div-block-11 af-class-div-block-12 af-class-div-block-13 af-class-div-block-14 af-class-div-block-15 af-class-div-block-16">
                    <div className="af-class-text-block-2">Sync your leagues from:</div>
                    </div>
                    <div className="af-class-logos af-class-big"><img src="images/NFFC.svg" loading="lazy" height={88} alt className="af-class-image-6" /><img src="images/Yahoo-logo.png" loading="lazy" height={52} alt className="af-class-image-7" /><img src="images/ESPN_wordmark.svg" loading="lazy" height={44} alt className="af-class-image-8" /><img src="images/Sleeper-Icon.png" loading="lazy" height={96} alt className="af-class-image-9" /></div>
                    <div className="af-class-logos af-class-tablet"><img src="images/NFFC.svg" loading="lazy" width={44} alt className="af-class-image-6" /><img src="images/Yahoo-logo.png" loading="lazy" height={32} alt className="af-class-image-7" /><img src="images/ESPN_wordmark.svg" loading="lazy" height={27} alt className="af-class-image-8" /><img src="images/Sleeper-Icon.png" loading="lazy" height={56} alt className="af-class-image-9" /></div>
                    <div className="af-class-logos af-class-desktop"><img src="images/NFFC.svg" loading="lazy" width={60} alt className="af-class-image-6" /><img src="images/Yahoo-logo.png" loading="lazy" height={44} alt className="af-class-image-7" /><img src="images/ESPN_wordmark.svg" loading="lazy" height={34} alt className="af-class-image-8" /><img src="images/Sleeper-Icon.png" loading="lazy" height={80} alt className="af-class-image-9" /></div>
                    <div className="af-class-logos af-class-mobile">
                    <div className="af-class-div-block-26"><img src="images/Yahoo-logo.png" loading="lazy" height={24} alt className="af-class-image-7" /><img src="images/NFFC.svg" loading="lazy" width={36} alt className="af-class-image-6" /><img src="images/ESPN_wordmark.svg" loading="lazy" height={21} alt className="af-class-image-8" /><img src="images/Sleeper-Icon.png" loading="lazy" height={50} alt className="af-class-image-9" /></div>
                    </div>
                </div>
                </section>
                <div className="af-class-content af-class-_3">
                <div className="af-class-container-main af-class-content3">
                    <div className="af-class-container-50 af-class-content3 af-class-states">
                    <div className="af-class-container-text af-class-content3 af-class-responsive">
                        <h3 className="af-class-heading af-class-content3">Licensed in over 20 states</h3>
                        <p className="af-class-paragraph">With more states to come!</p>
                        <div className="af-class-container-states">
                        <div className="af-class-container-6">
                            <div className="af-class-state-name">Arkansas</div>
                            <div className="af-class-state-name">California</div>
                            <div className="af-class-state-name">Colorado</div>
                            <div className="af-class-state-name">Connecticut</div>
                            <div className="af-class-state-name">Georgia</div>
                            <div className="af-class-state-name">Illinois</div>
                            <div className="af-class-state-name">Florida</div>
                        </div>
                        <div className="af-class-container-6">
                            <div className="af-class-state-name">Kansas</div>
                            <div className="af-class-state-name">Kentucky</div>
                            <div className="af-class-state-name">Maine</div>
                            <div className="af-class-state-name">Massachusetts</div>
                            <div className="af-class-state-name">Michigan</div>
                            <div className="af-class-state-name">Minnesota</div>
                            <div className="af-class-state-name">Nebraska</div>
                        </div>
                        <div className="af-class-container-6">
                            <div className="af-class-state-name">North Carolina</div>
                            <div className="af-class-state-name">North Dakota</div>
                            <div className="af-class-state-name">Oklahoma</div>
                            <div className="af-class-state-name">Oregon</div>
                            <div className="af-class-state-name">Rhode Island</div>
                            <div className="af-class-state-name">South Carolina</div>
                        </div>
                        <div className="af-class-container-6">
                            <div className="af-class-state-name">Texas</div>
                            <div className="af-class-state-name">Tenessee</div>
                            <div className="af-class-state-name">Utah</div>
                            <div className="af-class-state-name">West Virginia</div>
                            <div className="af-class-state-name">Wisconsin</div>
                            <div className="af-class-state-name">Wyoming</div>
                        </div>
                        </div>
                    </div>
                    <div className="af-class-container-text af-class-content3 af-class-mobile">
                        <h3 className="af-class-heading af-class-content3">Licensed in 28 states</h3>
                        <p className="af-class-paragraph">With more states to come!</p>
                        <div className="af-class-container-states">
                        <div className="af-class-container-6 af-class-_33">
                            <div className="af-class-state-name">Arkansas</div>
                            <div className="af-class-state-name">California</div>
                            <div className="af-class-state-name">Colorado</div>
                            <div className="af-class-state-name">Connecticut</div>
                            <div className="af-class-state-name">Florida</div>
                            <div className="af-class-state-name">Georgia</div>
                            <div className="af-class-state-name">Illinois</div>
                            <div className="af-class-state-name">Kansas</div>
                            <div className="af-class-state-name">Kentucky</div>
                            <div className="af-class-state-name">Maine</div>
                        </div>
                        <div className="af-class-container-6 af-class-_33">
                            <div className="af-class-state-name">Massachusetts</div>
                            <div className="af-class-state-name">Michigan</div>
                            <div className="af-class-state-name">Minnesota</div>
                            <div className="af-class-state-name">Nebraska</div>
            
                            <div className="af-class-state-name">North Carolina</div>
                            <div className="af-class-state-name">North Dakota</div>
                            <div className="af-class-state-name">Ohio</div>
                            <div className="af-class-state-name">Oklahoma</div>
                            <div className="af-class-state-name">Oregon</div>
                        </div>
                        <div className="af-class-container-6 af-class-_33">
                            <div className="af-class-state-name">Rhode Island</div>
                            <div className="af-class-state-name">South Carolina</div>
                            <div className="af-class-state-name">Texas</div>
                            <div className="af-class-state-name">Tenessee</div>
                            <div className="af-class-state-name">Utah</div>
                            <div className="af-class-state-name">West Virginia</div>
                            <div className="af-class-state-name">Wisconsin</div>
                            <div className="af-class-state-name">Wyoming</div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="af-class-image-contain af-class-content3"><img src="images/Black-States-01.svg" loading="lazy" alt className="af-class-image-4" /><img src="images/Green-States-28.svg" loading="lazy" data-w-id="a3ea0739-f23a-c537-d8b4-a4ba5f88680a" alt className="af-class-image-5" /></div>
                </div>
                </div>
                <section className="af-class-section-3">
                <div className="af-class-sectioncontainer" style={{ overflow: "hidden" }}>
                    <div className="af-class-div-block-17">
                    <div className="af-class-text-block-6">FAQ</div>
                    </div>

                    {/* Single Question */}
                    <div data-hover="false" data-delay={0} data-w-id="d82762eb-4d62-6521-1aab-959311a5c578" style={{height: 80}} className="af-class-accordion-item w-dropdown">
                        <div className="af-class-accordion-toggle w-dropdown-toggle">
                            <div style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} className="af-class-accordion-icon w-icon-dropdown-toggle" />
                            <div className="af-class-text-block-3">Who can I see in my contacts list?</div>
                        </div>
                        <nav className="af-class-dropdown-list w-dropdown-list">
                            <p className="af-class-dropdown-link">In the Chat tab, your Contacts list will be populated by users you've played in the arena, leaguemates that have joined, and anyone that you've referred to the platform using your unique referral link.</p>
                        </nav>
                    </div>

                    {/* Single Question */}
                    <div data-hover="false" data-delay={0} data-w-id="d82762eb-4d62-6521-1aab-959311a5c578" style={{height: 80}} className="af-class-accordion-item w-dropdown">
                        <div className="af-class-accordion-toggle w-dropdown-toggle">
                            <div style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} className="af-class-accordion-icon w-icon-dropdown-toggle" />
                            <div className="af-class-text-block-3">Where can I play paid contests?</div>
                        </div>
                        <nav className="af-class-dropdown-list w-dropdown-list">
                            <p className="af-class-dropdown-link">28 states and counting - California, Colorado, Connecticut, Florida, Georgia, Illinois, Kansas, Kentucky, Maine, Massachusetts, Michigan, Minnesota, Nebraska, New Jersey, North Carolina, North Dakota, Ohio, Oklahoma, Oregon, Rhode Island, South Carolina, Texas, Tenessee, Utah, West Virginia, Wisconsin, Wyoming</p>
                        </nav>
                    </div>

                    {/* Single Question */}
                    <div data-hover="false" data-delay={0} data-w-id="d82762eb-4d62-6521-1aab-959311a5c578" style={{height: 80}} className="af-class-accordion-item w-dropdown">
                        <div className="af-class-accordion-toggle w-dropdown-toggle">
                            <div style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} className="af-class-accordion-icon w-icon-dropdown-toggle" />
                            <div className="af-class-text-block-3">What season long platforms do you support?</div>
                        </div>
                        <nav className="af-class-dropdown-list w-dropdown-list">
                            <p className="af-class-dropdown-link">We're integrated with Yahoo, ESPN, Sleeper, NFFC, and My Fantasy League, with more platforms on the way!</p>
                        </nav>
                    </div>

                    {/* Single Question */}
                    <div data-hover="false" data-delay={0} data-w-id="d82762eb-4d62-6521-1aab-959311a5c578" style={{height: 80}} className="af-class-accordion-item w-dropdown">
                        <div className="af-class-accordion-toggle w-dropdown-toggle">
                            <div style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} className="af-class-accordion-icon w-icon-dropdown-toggle" />
                            <div className="af-class-text-block-3">What is the scoring format for football?</div>
                        </div>
                        <nav className="af-class-dropdown-list w-dropdown-list">
                            <div className="af-class-dropdown-link">The Arena scoring is a half PPR format. Full scoring details
                                    <ul>
                                        <li>1 Passing Yard =.04 Pts</li>
                                        <li>1 Passing TD = 4 Pts</li>
                                        <li>1 Interception = -2 Pts</li>
                                        <li>1 Rushing Yard = 0.1 Pts</li>
                                        <li>1 Rushing TD = 6 Pts</li>
                                        <li>1 Reception = 0.5 Pts</li>
                                        <li>1 Receiving Yard = 0.1 Pts</li>
                                        <li>1 Receiving TD = 6 Pts</li>
                                        <li>1 Punt/Kickoff/FG Return for TD = 6 Pts</li>
                                        <li>Fumble Lost = -2 Pts</li>
                                        <li>2 Pt Conversion (Pass, Run, or Catch) = 2 Pts</li>
                                        <li>Offensive Fumble Recovery TD = 6 Pts</li>
                                    </ul>
                                </div>
                        </nav>
                    </div>

                    {/* Single Question */}
                    <div data-hover="false" data-delay={0} data-w-id="d82762eb-4d62-6521-1aab-959311a5c578" style={{height: 80}} className="af-class-accordion-item w-dropdown">
                        <div className="af-class-accordion-toggle w-dropdown-toggle">
                            <div style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} className="af-class-accordion-icon w-icon-dropdown-toggle" />
                            <div className="af-class-text-block-3">What happens if my Arena proposal doesn't get accepted?</div>
                        </div>
                        <nav className="af-class-dropdown-list w-dropdown-list">
                            <p className="af-class-dropdown-link">If any of the proposals you send don't get accepted or acknowledged before game time, they will expire and funds will be returned back to your wallet.</p>
                        </nav>
                    </div>

                    {/* Single Question */}
                    <div data-hover="false" data-delay={0} data-w-id="d82762eb-4d62-6521-1aab-959311a5c578" style={{height: 80}} className="af-class-accordion-item w-dropdown">
                        <div className="af-class-accordion-toggle w-dropdown-toggle">
                            <div style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} className="af-class-accordion-icon w-icon-dropdown-toggle" />
                            <div className="af-class-text-block-3">Why do you need my address?</div>
                        </div>
                        <nav className="af-class-dropdown-list w-dropdown-list">
                            <p className="af-class-dropdown-link">We use your address to verify your identity and ensure you're in one of our licensed markets to play paid contests. We never share user information with any third parties.</p>
                        </nav>
                    </div>

                    {/* Single Question */}
                    <div data-hover="false" data-delay={0} data-w-id="d82762eb-4d62-6521-1aab-959311a5c578" style={{height: 80}} className="af-class-accordion-item w-dropdown">
                        <div className="af-class-accordion-toggle w-dropdown-toggle">
                            <div style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} className="af-class-accordion-icon w-icon-dropdown-toggle" />
                            <div className="af-class-text-block-3">How do I add funds to my account?</div>
                        </div>
                        <nav className="af-class-dropdown-list w-dropdown-list">
                            <p className="af-class-dropdown-link">If you need to access your wallet to add funds, just click the link in the top right of the Arena screen. From there, you can add funds via ACH or credit card.</p>
                        </nav>
                    </div>

                    {/* Single Question */}
                    <div data-hover="false" data-delay={0} data-w-id="d82762eb-4d62-6521-1aab-959311a5c578" style={{height: 80}} className="af-class-accordion-item w-dropdown">
                        <div className="af-class-accordion-toggle w-dropdown-toggle">
                            <div style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} className="af-class-accordion-icon w-icon-dropdown-toggle" />
                            <div className="af-class-text-block-3">Why can't I withdraw my bonus credit?</div>
                        </div>
                        <nav className="af-class-dropdown-list w-dropdown-list">
                            <p className="af-class-dropdown-link">Bonus credits awarded to your account are only eligible to be redeemed in the Arena for play. Any winnings earned from matches played bonus credits are fully eligible for withdrawal!</p>
                        </nav>
                    </div>

                    {/* Single Question */}
                    <div data-hover="false" data-delay={0} data-w-id="d82762eb-4d62-6521-1aab-959311a5c578" style={{height: 80}} className="af-class-accordion-item w-dropdown">
                        <div className="af-class-accordion-toggle w-dropdown-toggle">
                            <div style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0deg) skew(0, 0)'}} className="af-class-accordion-icon w-icon-dropdown-toggle" />
                            <div className="af-class-text-block-3">What happens if a player gets hurt after my lineup is locked?</div>
                        </div>
                        <nav className="af-class-dropdown-list w-dropdown-list">
                            <p className="af-class-dropdown-link">If a player gets hurt or is ruled out after you've accepted a match, we will automatically sub in an eligible player with the highest projected point total on your bench.</p>
                        </nav>
                    </div>
                </div>
                </section>
                <div className="af-class-section-4">
                <div className="af-class-div-block-21">
                    <div className="af-class-div-block-20"><img src="images/Bettle-Logo.svg" loading="lazy" width={240} alt className="af-class-image-13" />

                        {/* Store Links */}
                        <div className="af-class-div-block-28">
                            <img src="images/Logo.png" loading="lazy" height={48} alt className="af-class-image-14 pointer" onClick={() => window.open('https://play.google.com/store/apps/details?id=com.bettle.bettlelive&hl=en_US&gl=US')} />
                            <img src="images/Logo-1.png" loading="lazy" height={48} alt className="af-class-image-15 pointer" onClick={() => window.open('https://apps.apple.com/us/app/bettle-fantasy-sports/id1591274931')}/>
                        </div>
                    </div>
                    <div className="af-class-div-block-19">
                    <div className="af-class-div-block-18">
                        <div className="af-class-text-block-7 pointer">About Us</div>
                        <div className="af-class-text-block-7 pointer">
                            <a target={"_blank"} href="https://bettlesupport.zendesk.com/hc/en-us" >
                                Help Center
                            </a>
                        </div>
                        <div className="af-class-text-block-7 pointer">
                            <a target={"_blank"} href="https://bettle.co/responsiblegaming" >
                                Responsible Gaming Policy
                            </a>
                        </div>

                        {/* Social */}
                        <div className="af-class-div-block-22">
                            <img src="images/Twitter-logo.svg" loading="lazy" height={26} alt className="af-class-image-10 pointer"  onClick={() => window.open('https://mobile.twitter.com/bettlefantasy')} />
                            <img src="images/Instagram_logo_2022.svg" loading="lazy" height={32} alt className="af-class-image-12 pointer" onClick={() => window.open('https://www.instagram.com/bettlefantasy/?hl=en')} />
                            <img src="images/Discord_logo.svg" loading="lazy" height={24} alt className="af-class-image-11 pointer" onClick={() => window.open('https://discord.gg/5jk2uUhEVq')} />
                            <img src="images/LinkedIn_Logo.svg" loading="lazy" height={32} alt className='pointer' onClick={() => window.open('https://www.linkedin.com/company/bettleinc/')} />
                        </div>
                    </div>
                    </div>
                </div>
                <div className="af-class-line" />

                <div className="af-class-div-block-23">
                    <div className="af-class-text-block-8">
                            <span>
                            Please play responsibly and only bet what you can afford. If you or someone you know has a gambling problem, help is available. Call 1-800-GAMBLER or go to
                            </span>
                            <a target="_blank" href="https://ncpgambling.org/" className="af-class-link"> ncpgambling.org</a>. 
                            &copy; 2021 - 2022 Bettle Inc. All Rights Reserved. 
                            <a href="/privacy" className="af-class-text-span">Privacy Policy </a>
                            &nbsp;
                            <a href='/terms-and-conditions' className="af-class-text-span"> Terms &amp; Conditions </a> 
                    </div>
                </div>

                </div>
            </div>
            </span>
        </span>
        {this.state?.signInModal && <SignInModal screen={this.state?.authScreen} isVisible={this.state?.signInModal} closeModal={() => this.setState({ signInModal: false })} />}
        </>
    )
  }
}

const mapStateToProps = (state) => ({ authenticated: state.auth.authenticated})
export default connect(mapStateToProps, null) (withRouter(IndexView))

/* eslint-enable */
